@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

body {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

header {
  background-color: #FAF1D3;
  color: black;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

}

header nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1.6;
  font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

header nav ul li {
  margin: 0 15px;
}

header nav ul li a {
  color: #333;
  cursor: pointer;
  font-size: 0.8rem;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

section {

  /* border-bottom: 1px solid #ccc; */
}

footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
}

.primary-btn {
  background: #FFE793;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer
}

@media (max-width: 698px) {
  .about_us_article {
    flex-wrap: wrap;
  }

  .article h2,
  p {
    text-align: center;
  }
}

#products .rating {
  justify-content: center;
}

.rating .star {
  color: #f5f3ef;
  margin-right: 5px;
  background-color: blue;
  display: flex;
  height: 16px;
  width: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  padding: 0px 1px 2px 1px;

}