.card {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    width: 300px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-image img {
    width: 60%;
    height: auto;
    display: block;
}

.discount-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #00c853;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
}

.card-details {
    padding: 20px;
    text-align: left;
    color: #0000008c;
}

.rating {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}



.card-details h3 {
    font-size: 18px;
    margin: 10px 0;
}

.card-details p {
    font-size: 14px;
    color: #757575;
    text-align: left;
    margin: 0px;
}

.price {
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: space-between;
}

.original-price {
    text-decoration: line-through;
    color: #009846;
    margin-right: 10px;
    font-size: 10px;
}

.discounted-price {
    font-size: 16px;
    color: #000000;
}

.shop-now-button {
    background-color: #B5291C;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.shop-now-button:hover {
    background-color: #b71c1c;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}